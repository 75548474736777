import React, { useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from 'react-router-dom';

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, getPersonalReferralDetailsById, getUserDetails } from "../../../redux/actions/data.action";
import moment from "moment";
import CustomModal from "../../../component/Modals/CustomModal";
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PEROSNAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS } from "../../../redux/actions/types";
import { Button, FormFeedback, Input, Label } from "reactstrap";
import { isNotFutureDate } from "../../../Utils/validators";


const One = (props) => {
  const { examination, lastExamination, personalReferralDetails } = props;
  const { viewOption, edited, modal } = useSelector(({ ui }) => ui)
  const { examinationDetails } = useSelector(({ data }) => data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clinician, setClinician] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [errors, setErrors] = useState({});

  const setExamDetails = (prop) => {
    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    dispatch({ type: SET_EXAMINATION_DETAILS, payload: prop })
    localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: prop }))
    dispatch({ type: SET_EDITED, payload: 'examination' })
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    // event.stopPropagation();


    if (!examinationDetails.ExaminationID && edited.find(a => a === 'examination') && !moment(examinationDetails.ExaminationDate).isSame(moment(personalReferralDetails.AppointmentDateCervix), 'day') && (!validated || !isContinue)) {


      dispatch({ type: OPEN_MODAL, payload: 'appointmentWarning' })
      return;
    }

    if (examinationDetails.NoExam || (examinationDetails.DiscussionOnly == 'true' || examinationDetails.DiscussionOnly === true)) {
      setValidated(false)
      navigate(`${viewOption === 'enter' ? '/dashboard/patient/management' : '/dashboard/viewPatient/management'}`)

    } else {

      setValidated(false)
      // props.nextStep();
      if (examinationDetails.NurseLedClinic) {
        navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/NurseLedClinic' : '/dashboard/viewPatient/examination/NurseLedClinic'}`)
        return
      }

      if (examinationDetails.CytologyOnly) {
        navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/CytologyOnly' : '/dashboard/viewPatient/examination/CytologyOnly'}`)
        return
      }

      // if (examinationDetails.Pregnant) {
      navigate(`${viewOption === 'enter' ? '/dashboard/patient/colposcopicFindings' : '/dashboard/viewPatient/colposcopicImaging'}`)
      // }



    }



    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // } else {
    //   setError("");
    //   props.nextStep();
    //   event.preventDefault();
    //   event.stopPropagation();
    // }

    // if(examinationDetails.Pregnant){
    //   if(pregnantContinue){
    //   console.log(pregnantContinue, 'cont')
    //     props.nextStep();
    //   } else {
    //     navigate(`${viewOption === 'enter' ?'/dashboard/patient/management' : '/dashboard/viewPatient/management' }`)
    //   }

    // }
    // props.goToStep(3);

    /*  if(examinationDetails.DiscussionOnly){
       // navigate(`${viewOption === 'enter' ?'/dashboard/patient/management' : '/dashboard/viewPatient/management' }`)
     } else {
           props.nextStep();
     } */

    // setValidated(true);
  };

  const handleChanges = prop => event => {
    setErrors({})
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    if (prop === 'ExaminationDate' && !isNotFutureDate(event.target.value)) {
      setErrors({ ExaminationDate: `Examination Date can not be after today's date!` })
    }



    // console.log(examinationDetails.)
    if ((!examinationDetails.Name && (prop === 'Pregnant' || prop === 'DiscussionOnly' || prop === 'CytologyOnly' || prop === 'NurseLedClinic'))) {
      dispatch({ type: OPEN_MODAL, payload: 'colposcopistEmpty' });
      return;
    }

    let newVal = { ...examinationDetails, [prop]: event.target.value }


    if (prop === 'DiscussionOnly' && (examinationDetails.Pregnant == 'true' || examinationDetails.Pregnant === true) && (event.target.value == 'false' || event.target.value === false)) {
      newVal = { ...newVal, DiscussionOnly: false, Pregnant: false }
    }


    if (prop === 'Pregnant') {
      if ((event.target.value == 'true' || event.target.value === true)) {
        dispatch({ type: OPEN_MODAL, payload: 'patientPregnant' })
      }
    }




    dispatch({ type: SET_EXAMINATION_DETAILS, payload: newVal })
    localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: newVal }))
    dispatch({ type: SET_EDITED, payload: 'examination' })

  }

  const handleChangeClinician = (e) => {

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let selcted = clinician.find(a => a.iUserID == e.target.value)
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    if (selcted) {
      let { Status, Post, iUserID, Title, SiteID } = selcted;


      dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...examinationDetails, Name: iUserID, Post: Post ? Post : '', Status: Status ? Status : '', Title: Title ? Title : '', ExaminationAtSite: SiteID, tbl_userdetail: selcted } })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: { ...examinationDetails, Name: iUserID, Post: Post ? Post : '', Status: Status ? Status : '', Title: Title ? Title : '', ExaminationAtSite: SiteID, tbl_userdetail: selcted } }))


    } else {
      dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...examinationDetails, Name: null, Post: '', Status: '', Title: '', ExaminationAtSite: null, tbl_userdetail: {} } })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: { ...examinationDetails, Name: null, Post: '', Status: '', Title: '', ExaminationAtSite: null, tbl_userdetail: {} } }))
    }
    dispatch({ type: SET_EDITED, payload: 'examination' })

  }

  const handleExit = (id) => {
    if (edited.find(a => a === 'examination')) {
      dispatch({ type: OPEN_MODAL, payload: 'save_details' })
      // return

    } else {
      dispatch({ type: CLEAR_EXAMINATION })
      dispatch({ type: CLEAR_EXAMINATION_DETAILS })

    }


    navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
  }

  const handleGetClinics = () => {

    dispatch(getUserDetails({ Access: 2 }))
      .then(res => {

        if (res) {
          setClinician(res)
        }
      })

  }



  useEffect(() => {
    handleGetClinics()
    let edited = localStorage.getItem('edited');
    if (viewOption === 'review' && !examination.ExaminationID) {
      navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
    }

    edited = edited ? JSON.parse(edited) : []

    if (edited.length !== 0) {
      edited.forEach(a => {
        dispatch({ type: SET_EDITED, payload: a })
      })
    }
    return () => {
      setValidated(false)
      setIsContinue(false)
    }
  }, [])


  return (
    <div>
      <CustomModal
        open={modal === 'appointmentWarning'}
        title="Follow Up Examination"
        size="lg"
        onYes={() => {
          setValidated(true)
          setIsContinue(true)

          if ((examinationDetails.DiscussionOnly == 'true' || examinationDetails.DiscussionOnly === true)) {
            dispatch({ type: OPEN_MODAL });
            navigate(`${viewOption === "enter" ? "/dashboard/patient/management" : "/dashboard/viewPatient/management"}`)
            return
          }

          if ((examinationDetails.NurseLedClinic == 'true' || examinationDetails.NurseLedClinic === true)) {
            dispatch({ type: OPEN_MODAL });
            navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/NurseLedClinic' : '/dashboard/viewPatient/examination/NurseLedClinic'}`)
            return;
          }

          if ((examinationDetails.CytologyOnly == 'true' || examinationDetails.CytologyOnly === true)) {
            dispatch({ type: OPEN_MODAL });
            navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/CytologyOnly' : '/dashboard/viewPatient/examination/CytologyOnly'}`)
            return;
          }



          if ((examinationDetails.Pregnant == 'true' || examinationDetails.Pregnant === true) || ((examinationDetails.NurseLedClinic == 'false' || !examinationDetails.NurseLedClinic) && (examinationDetails.CytologyOnly == 'false' || !examinationDetails.CytologyOnly) && (examinationDetails.Pregnant == 'false' || !examinationDetails.Pregnant))) {
            // navigate(`${viewOption === 'enter' ? '/dashboard/patient/colposcopicImaging' : '/dashboard/viewPatient/colposcopicImaging'}`)

            navigate(`${viewOption === 'enter' ? '/dashboard/patient/colposcopicFindings' : '/dashboard/viewPatient/colposcopicImaging'}`)
            dispatch({ type: OPEN_MODAL });
            return;

          }


          dispatch({ type: OPEN_MODAL });
          navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/details' : '/dashboard/viewPatient/examination/details'}`)

        }}
        onNo={() => {
          setIsContinue(false)
          setValidated(true)
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="ti-help-alt  text-info mr-3 fa-2x"></i>
            <span style={{ fontSize: '13px' }}>An appointment has not been scheduled to correspond with this examinaton.</span>
          </div>
          <span style={{ fontSize: '13px' }}>Do you wish to continue?</span>

        </div>

      </CustomModal>
      <CustomModal
        open={modal === 'colposcopistEmpty'}
        title="Follow Up Examination"
        size="lg"
        onOkay={() => {
          dispatch({ type: OPEN_MODAL });
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <i className="ti-alert text-warning mr-3 fa-2x"></i>
          <span style={{ fontSize: '13px' }}>The Colposcopist name needs completing before you can continue</span>
        </div>

      </CustomModal>
      <CustomModal
        open={modal === 'patientPregnant'}
        title="Patient Pregnant"
        onNo={() => {
          let formDetails = localStorage.getItem('formDetails');
          let frm = formDetails ? JSON.parse(formDetails) : {};
          // setIsDischarge(false)
          dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...examinationDetails, NoExam: false } })
          localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: { ...examinationDetails, DiscussionOnly: true } }))

        }}
        onYes={() => {
          let formDetails = localStorage.getItem('formDetails');
          let frm = formDetails ? JSON.parse(formDetails) : {};
          // setIsDischarge(false)
          dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...examinationDetails, NoExam: true } })
          localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: { ...examinationDetails, DiscussionOnly: false } }))

          dispatch({ type: OPEN_MODAL });
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <i className="ti-help-alt text-info mr-3 fa-2x"></i>
          <span >Do you wish to continue with the examination?</span>
        </div>

      </CustomModal>

      <Form
        id="formValid"
        noValidate
        // validated={validated}
        onSubmit={handleSubmit}
      >
        <div className="form-body txtLeft">
          <Row className="row p-t-20">
            <Row>
              <Col xs="12" sm="8" className="mt-3">
                <div className="bordered">
                  <h3 className="card-title txtLeft bordered-title">Clinician</h3>
                  <div className='row pl-3 pr-3'>
                    <div className='col-md-3'>
                      <label className="control-label text-blue">Title</label>
                      <input type="text" id="firstName" className="form-control" placeholder=""
                        value={examinationDetails.Title}
                      />
                    </div>
                    <div className='col-md-9'>
                      <label className="control-label text-blue">Name</label>

                      <select name="marital-Status" id="marital-Status" className="form-control"
                        value={examinationDetails.Name}
                        onChange={(e) => handleChangeClinician(e)}

                      >
                        <option value={null}>Select</option>
                        {clinician.length !== 0 && clinician.map(a => {
                          return (
                            <option value={a.iUserID}>{a.Name}</option>
                          )
                        })}
                      </select>
                    </div>

                    <div className='clearfix mt-2'></div>

                    <div className='col-md-6'>
                      <label className="control-label text-blue">Post</label>
                      <input type="text" id="firstName" className="form-control" placeholder=""
                        value={examinationDetails.Post}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className="control-label text-blue">Status</label>
                      <input type="text" id="firstName" className="form-control" placeholder=""
                        value={examinationDetails.Status}
                      />
                    </div>
                    <div className='clearfix mt-2'></div>
                    <div className='col-md-12'>
                      <label className="control-label text-blue">Trainer</label>
                      <input type="text" id="firstName" className="form-control" placeholder="" disabled />
                    </div>
                    <div className='clr'></div>
                    <br />

                  </div>

                </div>
              </Col>
              <Col xs="12" sm="4" style={{ display: 'flex' }} >
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'space-between' }}>

                  <div className="bordered mt-3">
                    <h3 className="card-title bordered-title">Examination Date</h3>
                    <div className="pl-3 pr-3 pb-3">
                      <Input
                        type="date" id="ExaminationDate" className="form-control" placeholder=""
                        value={examinationDetails.ExaminationDate ? moment(examinationDetails.ExaminationDate).format('YYYY-MM-DD') : ''}
                        onChange={handleChanges('ExaminationDate')}
                        invalid={errors.ExaminationDate}
                      />
                      <FormFeedback>
                        {errors.ExaminationDate}
                      </FormFeedback>

                    </div>
                  </div>
                  <div className="bordered mt-3">
                    <h3 className="card-title bordered-title">Chaperone</h3>
                    <div className="pl-3 pr-3 pb-3">
                      <input type="text" id="ExaminationChaperone" className="form-control" placeholder=""
                        value={examinationDetails.ExaminationChaperone}
                        onChange={handleChanges('ExaminationChaperone')}
                      />
                    </div>
                  </div>
                </div>
              </Col>

              <div className='clr'></div>
              <br />
            </Row>
            <Row className="">
              <div>
                {lastExamination && lastExamination.ExaminationID ?
                  <>
                    <div className="bordered  pr-2 pl-2 pb-2">
                      <h3 className="card-title text-center bordered-title">Review of Previous Colposcopy</h3>
                      <div className='bordered pr-2 pl-2'>
                        <h3 className="card-title bordered-title text-15">Complications</h3>
                        <div className="d-flex pl-4">

                          <div className='col-md-3 '>
                            <Input type="checkbox" name="PainAfterTreatment" className=""
                              id="PainAfterTreatment"
                              value={examinationDetails.PainAfterTreatment}
                              checked={examinationDetails.PainAfterTreatment}
                              onClick={(e) => {
                                setExamDetails({ ...examinationDetails, PainAfterTreatment: e.target.value === 'true' ? false : true })
                              }}

                            />
                            {' '}
                            <Label htmlFor="PainAfterTreatment" className="ml-2">Pain</Label>
                            <div className='clearfix'></div><br />
                          </div>
                          <div className='col-md-3 '>
                            <Input type="checkbox" name="CervicalStenosis" className=""
                              id="CervicalStenosis"
                              value={examinationDetails.CervicalStenosis}
                              checked={examinationDetails.CervicalStenosis}
                              onClick={(e) => {
                                setExamDetails({ ...examinationDetails, CervicalStenosis: e.target.value === 'true' ? false : true })
                              }}

                            />
                            {' '}
                            <Label htmlFor="CervicalStenosis" className="ml-2">Cervical Stenosis</Label>
                            <div className='clearfix'></div><br />
                          </div>
                          <div className='col-md-3 '>
                            <Input type="checkbox" name="BleedingAfterTreatment" className=""
                              id="BleedingAfterTreatment"
                              value={examinationDetails.BleedingAfterTreatment}
                              checked={examinationDetails.BleedingAfterTreatment}
                              onClick={(e) => {

                                setExamDetails({ ...examinationDetails, BleedingAfterTreatment: e.target.value === 'true' ? false : true, Antibiotics: e.target.value === 'true' ? false : examinationDetails.Antibiotics })
                              }}

                            />
                            {' '}
                            <Label htmlFor="BleedingAfterTreatment" className="ml-2" >Bleeding</Label>
                            <div className='clearfix'></div><br />
                          </div>
                        </div>
                        <div className="d-flex pl-4">
                          <div className='col-md-3 '>
                            <Input type="checkbox" name="InfectionAfterTreatment" className=""
                              id="InfectionAfterTreatment"
                              value={examinationDetails.InfectionAfterTreatment}
                              checked={examinationDetails.InfectionAfterTreatment}
                              onClick={(e) => {
                                setExamDetails({ ...examinationDetails, InfectionAfterTreatment: e.target.value === 'true' ? false : true })
                              }}

                            />
                            {' '}
                            <Label htmlFor="InfectionAfterTreatment" className="ml-2" >Infection</Label>
                            <div className='clearfix'></div><br />
                          </div>
                          <div className='col-md-3 '>
                            <Input type="checkbox" name="DiscomfortAfterTreatment" className=""
                              id="DiscomfortAfterTreatment"
                              value={examinationDetails.DiscomfortAfterTreatment}
                              checked={examinationDetails.DiscomfortAfterTreatment}
                              onClick={(e) => {
                                setExamDetails({ ...examinationDetails, DiscomfortAfterTreatment: e.target.value === 'true' ? false : true })
                              }}

                            />
                            {' '}
                            <Label htmlFor="DiscomfortAfterTreatment" className="ml-2" >Discomfort</Label>
                            <div className='clearfix'></div><br />
                          </div>
                          <div className='col-md-3 '>
                            <Input type="checkbox" name="Antibiotics" className=""
                              id="Antibiotics"
                              disabled={!examinationDetails.BleedingAfterTreatment}
                              value={examinationDetails.Antibiotics}
                              checked={examinationDetails.Antibiotics}
                              onClick={(e) => {
                                setExamDetails({ ...examinationDetails, Antibiotics: e.target.value === 'true' ? false : true })
                              }}

                            />
                            {' '}
                            <Label htmlFor="Antibiotics" className={`ml-2 ${!examinationDetails.BleedingAfterTreatment ? 'disabled' : ''}`} disabled={!examinationDetails.BleedingAfterTreatment} >Antibiotics given</Label>
                            <div className='clearfix'></div><br />
                          </div>



                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="bordered pr-2 pl-2 pb-2">
                      <h3 className="card-title text-center bordered-title">Clinical Condition</h3>
                      <div className="row">
                        {/* <div className="form-group m-5"> */}
                        <div className="col-md-4 mb-4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                          <label className="mr-5 text-blue">LMP</label>
                          <input type="text" id="firstName" className="form-control w-50" placeholder=""
                          // value={examinationDetails.ExaminationChaperone}
                          // onChange={handleChanges('ExaminationChaperone')}
                          />
                          {/* </div> */}
                        </div>
                        <div className="col-md-8">
                          <div className='bordered pr-2 pl-2'>
                            <h3 className="txtLeft card-title bordered-title">Contraception</h3>
                            <div className="row pl-3">
                              <div className='col-md-4'>
                                <input
                                  type="radio" id="As before"
                                  value="As before" checked={examinationDetails.Contraception === 'As before'}
                                  name="Contraception_radio"
                                  className=""
                                  onChange={handleChanges('Contraception')}
                                />
                                <label className="c text-blue" htmlFor="As before" >As before</label>
                                <div className='clearfix'></div><br />
                              </div>
                              <div className='col-md-4'>
                                <input
                                  type="radio" id="I.U.C.D"
                                  value="I.U.C.D"
                                  checked={examinationDetails.Contraception === 'I.U.C.D'}
                                  name="Contraception_radio" className="" onChange={handleChanges('Contraception')}
                                />
                                <label className="c text-blue" htmlFor="I.U.C.D">I.U.C.D</label>
                                <div className='clearfix'></div><br />
                              </div>
                              <div className='col-md-4'>
                                <input
                                  type="radio" id="None"
                                  value="None" checked={examinationDetails.Contraception === 'None'} name="Contraception_radio" className="" onChange={handleChanges('Contraception')}
                                />
                                <label className="c text-blue" htmlFor="None" >None</label>
                                <div className='clearfix'></div><br />
                              </div>
                            </div>
                            <div className="row pl-3">
                              <div className='col-md-4'>
                                <input
                                  type="radio" id="O.C.P"
                                  value="O.C.P" checked={examinationDetails.Contraception === 'O.C.P'} name="Contraception_radio" className="" onChange={handleChanges('Contraception')}
                                />
                                <label className="c text-blue" htmlFor="O.C.P" >O.C.P</label>
                                <div className='clearfix'></div><br />
                              </div>
                              <div className='col-md-4'>
                                <input
                                  type="radio" id="Barrier"
                                  value="Barrier" checked={examinationDetails.Contraception === 'Barrier'} name="Contraception_radio" className="" onChange={handleChanges('Contraception')}
                                />
                                <label className="c text-blue" htmlFor="Barrier" >Barrier</label>
                                <div className='clearfix'></div><br />
                              </div>
                              <div className='col-md-4 d-flex justify-center align-center h-50'>

                                <input
                                  type="radio" id="Other"
                                  value="Other" checked={examinationDetails.Contraception === 'Other'} name="Contraception_radio" className="" onChange={handleChanges('Contraception')}
                                />
                                <label className="mt-1 pt-2 text-blue" htmlFor="Other">Other</label>
                                <input type="text" name="ContraceptionOther" className="h-100 w-50 ml-3 form-control"
                                  onChange={handleChanges('ContraceptionOther')}
                                  disabled={examinationDetails.Contraception !== 'Other'}
                                  value={examinationDetails.ContraceptionOther}
                                />
                                <div className='clearfix'></div><br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <div className="bordered">
                    <h3 className="card-title txtLeft bordered-title">Gynaecological Examination</h3>
                    <div className='row pl-5 pr-5 mt-3'>
                      <div className='col-md-3'>
                        <input type="radio" name="VulvaAbnormal" className=""
                          value={examinationDetails.VulvaAbnormal}
                          id="VulvaAbnormal"
                          checked={examinationDetails.VulvaAbnormal}
                          onClick={(e) => {
                            setExamDetails({ ...examinationDetails, VulvaAbnormal: e.target.value === 'true' ? false : true })
                          }}
                        />
                        <label className="c text-blue" htmlFor="VulvaAbnormal" >Vulva abnormal</label>
                        <div className='clearfix'></div>
                      </div>
                      <div className='col-md-3'>
                        <input
                          type="radio" name="VaginaAbnormal" className=""
                          value={examinationDetails.VaginaAbnormal}
                          id="VaginaAbnormal"
                          checked={examinationDetails.VaginaAbnormal}
                          onClick={(e) => {
                            setExamDetails({ ...examinationDetails, VaginaAbnormal: e.target.value === 'true' ? false : true })
                          }}
                        />
                        <label className="c text-blue" htmlFor="VaginaAbnormal">Vagina abnormal</label>
                        <div className='clearfix'></div>
                      </div>

                      <div className='col-md-3'>
                        <input
                          type="radio"
                          name="adenexaeabnormal" className=""
                          value={examinationDetails.adenexaeabnormal}
                          id="adenexaeabnormal"
                          checked={examinationDetails.adenexaeabnormal}
                          onClick={(e) => {
                            setExamDetails({ ...examinationDetails, adenexaeabnormal: e.target.value === 'true' ? false : true })
                          }}

                        />
                        <label className="c text-blue" htmlFor="adenexaeabnormal" >Adenexae abnormal</label>
                        <div className='clearfix'></div>
                      </div>
                      <div className='col-md-3'>
                        <input type="radio" name="UterusAbnormal" className=""
                          value={examinationDetails.UterusAbnormal}
                          id="UterusAbnormal"
                          checked={examinationDetails.UterusAbnormal}
                          onClick={(e) => {
                            setExamDetails({ ...examinationDetails, UterusAbnormal: e.target.value === 'true' ? false : true })
                          }}
                        />
                        <label className="c text-blue" htmlFor="UterusAbnormal" >Uterus abnormal</label>
                        <div className='clearfix'></div>
                      </div>
                    </div>
                    <div className='col-md-12 pl-3 pr-3 mt-3'>
                      <label className="control-label  text-blue">Specify Abnormality</label>
                      <textarea name="textarea" id="textarea" className="form-control" placeholder="" aria-invalid="false"
                        value={examinationDetails.SpecifyAbnormality}
                        onChange={handleChanges('SpecifyAbnormality')}
                      />
                    </div>
                    <br />
                  </div>
                }
                <br />
              </div>
              <br />
              <div className="pl-3 mt-3">
                <div className='row pl-5 pr-5'>
                  <div className='col-md-3'>
                    <Input type="checkbox" name="Pregnant" className=""
                      id="Pregnant"
                      value={(examinationDetails.Pregnant == 'true' || examinationDetails.Pregnant === true) ? false : true}
                      checked={(examinationDetails.Pregnant == 'true' || examinationDetails.Pregnant === true) ? true : false}
                      onChange={handleChanges('Pregnant')}

                    />
                    {' '}
                    <Label className="pl-2 control-label" htmlFor="Pregnant" >Pregnant</Label>
                    <div className='clearfix'></div><br />
                  </div>
                  <div className='col-md-3'>
                    <Input type="checkbox" name="styled_radio" className=""
                      id="DiscussionOnly"
                      value={(examinationDetails.DiscussionOnly == 'true' || examinationDetails.DiscussionOnly === true) ? false : true}
                      checked={(examinationDetails.DiscussionOnly == 'true' || examinationDetails.DiscussionOnly === true) ? true : false}
                      onChange={handleChanges('DiscussionOnly')}


                    />
                    {' '}
                    <Label className="pl-2 control-label" htmlFor="DiscussionOnly" >Discussion Only</Label>
                    <div className='clearfix'></div><br />
                  </div>
                  <div className='col-md-3'>
                    <Input type="checkbox" name="CytologyOnly" className=""
                      id="CytologyOnly"
                      value={(examinationDetails.CytologyOnly == 'true' || examinationDetails.CytologyOnly === true) ? false : true}
                      checked={(examinationDetails.CytologyOnly == 'true' || examinationDetails.CytologyOnly === true) ? true : false}
                      onChange={handleChanges('CytologyOnly')}
                    />
                    {' '}
                    <Label className="pl-2 control-label" htmlFor="CytologyOnly" >Cytology Only</Label>
                    <div className='clearfix'></div><br />
                  </div>
                  <div className='col-md-3'>
                    <Input type="checkbox" name="styled_radio" className=""
                      id="NurseLedClinic"
                      value={(examinationDetails.NurseLedClinic == 'true' || examinationDetails.NurseLedClinic === true) ? false : true}
                      checked={(examinationDetails.NurseLedClinic == 'true' || examinationDetails.NurseLedClinic === true) ? true : false}
                      onChange={handleChanges('NurseLedClinic')}
                    />
                    {' '}
                    <label className="pl-2 control-label text-blue" htmlFor="NurseLedClinic" >Nurse Led Clinic</label>
                    <div className='clearfix'></div><br />
                  </div>
                </div>
              </div>
            </Row>
          </Row>
        </div>
        <br /><br />
        <div className="text-xs-center w-100">

          <Button type="submit" className="btn btn-dark marRight20 fL color-black" disabled>Add Notes</Button>

          <Button className="btn btnIri marRight20 fR"
            onClick={() => handleExit()}
          >EXIT</Button>
          <Button type="submit" className="btn btnIri marRight20 fR"
            disabled={!(examinationDetails.Name && Object.keys(errors).length === 0 ? true : false)}
          >NEXT</Button>

        </div>

      </Form>
    </div>

  );
};



const Examination = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { viewOption } = useSelector(({ ui }) => ui)
  const { patientDetails, examination, lastExamination, personalReferralDetails } = useSelector(({ data }) => data)
  const [stepWizard, setStepWizard] = useState(0);



  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const handleStepChange = (e) => {
    console.log(e);
    // setActiveStep(e.activeStep - 1);
  };

  const handleComplete = () => {
    // alert("You r done. TQ");
    navigate(`${viewOption === 'enter' ? '/dashboard/patient/management' : '/dashboard/viewPatient/management'}`)


  };

  let handleReferralDetails = (id) => {
    dispatch(getPersonalReferralDetailsById(id))
      .then((res) => {
        let { tbl_PersonalReferralReason, tbl_PersonalAssociatedSymptom, tbl_personalrelevantdetail } = res.data;
        dispatch({ type: SET_REFERRAL_REASONS, payload: tbl_PersonalReferralReason })
        dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: tbl_PersonalAssociatedSymptom })
        dispatch({ type: SET_PEROSNAL_RELEVANT_DETAILS, payload: tbl_personalrelevantdetail })

      })
      .catch(err => {
        console.log(err)
      })
  }



  const handleGetExamination = (id) => {
    dispatch(getCervixExaminationDetailsById(id))
      .then(res => {
        dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examination } })
      })
  }









  useEffect(() => {
    if (patientDetails && (patientDetails.NHSnumber || patientDetails.HospitalNumber)) {
      //   setPatient(patientDetails)
      if (personalReferralDetails && personalReferralDetails.ReferralID) {
        handleReferralDetails(personalReferralDetails.ReferralID)
      }


      if (examination && examination.ExaminationID) {
        handleGetExamination(examination.ExaminationID)
      }


    } else {
      navigate('/dashboard/home')
    }




  }, [patientDetails, examination])




  return (
    <>
      <div className="card-header bg-iri txtLeft">
        <h4 className="m-b-0 text-white">Examination Details</h4>
      </div>


      <div className="card-body">
        {/* <Row>
          <Col className='text-left'
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', lineHeight: 1 }}
          >
            <span className='text-primary'>{patientDetails.HospitalNumber}</span>
            <span className='text-primary '>{patientDetails.Forename} {patientDetails.Surname}</span>
            <span className='text-primary '>{moment(patientDetails.DateOfBirth).format('DD/MM/YYYY')}</span>

          </Col>
          <Col className='text-right'
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start', lineHeight: 1 }}
          >
            <h5 className='text-primary '>NHS No:{patientDetails.NHSnumber}</h5>
          </Col>
        </Row> */}
        <StepWizard
          instance={assignStepWizard}
          onStepChange={handleStepChange}
        >
          <One examination={examination} lastExamination={lastExamination} personalReferralDetails={personalReferralDetails} />
        </StepWizard>
      </div>
      <div className='clearfix'></div>
    </>
  )
}

export default Examination;
