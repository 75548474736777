export const env_vars = {
    // api_url: `http://localhost:4000/api`,
    
    api_url: 'https://compuscope.cloud/api',
    // api_url: 'https://irisoft-webapp02.azurewebsites.net/api',
    
    editor_api: 'http://localhost:3500',
    rtmp_url: 'https://sharewin.pro'
}

