import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CLEAR_EXAMINATIONS, CLEAR_PATIENT_DETAILS, CLEAR_PERSONAL_REFERRAL_DETAILS, CLEAR_REFERRAL_REASONS, OPEN_MODAL, SET_EXAMINATION, SET_EXAMINATIONS, SET_LAST_EXAMINATION } from '../../redux/actions/types';
import Table from 'react-bootstrap/Table';
import { getCervixExaminations } from '../../redux/actions/data.action';
import moment from 'moment';
import Logo from '../../assets/logo.png';


const ReviewOptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { patientDetails, examination, personalReferralDetails } = useSelector(({ data }) => data)
    const { viewOption } = useSelector(({ ui }) => ui)

    const [exam, setExam] = useState(null);
    const [examDates, setExamDates] = useState([])

    const handleExit = () => {
        dispatch({ type: CLEAR_PATIENT_DETAILS });
        dispatch({ type: CLEAR_PERSONAL_REFERRAL_DETAILS });
        dispatch({ type: CLEAR_REFERRAL_REASONS });

        localStorage.removeItem("formDetails")
        navigate('/dashboard/home')
    }


    const handleEnterExamination = () => {
        dispatch({ type: CLEAR_EXAMINATIONS });

        if (!personalReferralDetails) {
            return dispatch({ type: OPEN_MODAL, payload: 'noAppointmentModal' })
        }

        if (examDates.length !== 0 && examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID) > 0) {
            let ind = examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID)

            dispatch({ type: SET_EXAMINATIONS, payload: examDates })
            dispatch({ type: SET_EXAMINATION, payload: exam })
            dispatch({ type: SET_LAST_EXAMINATION, payload: examDates[ind - 1] })
            navigate('/dashboard/viewPatient/casereview')
        } else {
            if (exam) {
                dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                dispatch({ type: SET_EXAMINATION, payload: exam })
            }
            navigate('/dashboard/viewPatient/examination')
        }
    }


    const handleEnterManagement = () => {
        dispatch({ type: CLEAR_EXAMINATIONS });



        if (examDates.length !== 0 && examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID) > 0) {
            let ind = examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID)

            dispatch({ type: SET_EXAMINATIONS, payload: examDates })
            dispatch({ type: SET_EXAMINATION, payload: exam })
            dispatch({ type: SET_LAST_EXAMINATION, payload: examDates[ind - 1] })
            navigate('/dashboard/viewPatient/management')
        } else {
            if (exam) {
                dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                dispatch({ type: SET_EXAMINATION, payload: exam })
            }
            navigate('/dashboard/viewPatient/management')
        }
    }



    const handleImagingReview = () => {
        dispatch({ type: CLEAR_EXAMINATIONS });



        if (examDates.length !== 0 && examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID) > 0) {
            let ind = examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID)

            dispatch({ type: SET_EXAMINATIONS, payload: examDates })
            dispatch({ type: SET_EXAMINATION, payload: exam })
            dispatch({ type: SET_LAST_EXAMINATION, payload: examDates[ind - 1] })
            navigate('/dashboard/viewPatient/colposcopicImagingReview')
        } else {
            if (exam) {
                dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                dispatch({ type: SET_EXAMINATION, payload: exam })
            }
            navigate('/dashboard/viewPatient/colposcopicImagingReview')
        }
    }


    const handleExaminationDetails = (SystemID) => {
        dispatch(getCervixExaminations({ SystemID }))
            .then(res => {
                if (res && res.length !== 0) {
                    setExamDates(res)
                    setExam(res[res.length - 1])
                } else {
                    setExamDates([])
                    setExam(null)
                }
            })
    }


    useEffect(() => {
        if (patientDetails && patientDetails.SystemID) {
            handleExaminationDetails(patientDetails.SystemID)
        } else {
            navigate('/dashboard/home')
        }
        if (viewOption === 'enter') {
            navigate('/dashboard/patient')
        }
    }, [patientDetails.SystemID])

    return (
        <>
            <div className="card-header bg-iri w-100">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end'
                    }}
                >
                    <h4 className="text-white">Review Options</h4>
                </div>
            </div>
            <div className="card-body">
                <Row>
                    <Col xs="12" sm="7">
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                                onClick={() => navigate('/dashboard/viewPatient/enterPatient')}
                            >Personal</Button></Col> <Col className='text-left'><h5 className='text-primary'>Personal Details</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                disabled={(!patientDetails.SystemID || !exam)}
                                onClick={() => handleEnterExamination()}
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}>Examination</Button></Col> <Col className='text-left'><h5 className='text-primary'>Examination Details & Results</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                disabled={!exam}
                                onClick={() => handleEnterManagement()}
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                            >Management</Button></Col> <Col className='text-left'><h5 className='text-primary'>Most recent Management Details</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button

                                onClick={() => handleExit()}
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                            >Exit</Button></Col> <Col className='text-left'><h5 className='text-primary'>Exit Patient Details</h5></Col>
                        </Row>

                    </Col>
                    <Col xs="12" sm="5" style={{ width: '100%', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight: 30, paddingLeft: 30 }}>
                        <p className='pr-5 pl-5 p-10 w-100 text-center text-primary font-medium'>Specify the Examination Date for the Biopsy/Smear/Swab and any Treatment results: </p>
                        <div
                            className='trace-table'
                            style={{
                                minHeight: 200,
                                maxHeight: 250,
                                overflowX: 'hidden'
                            }}
                        >
                            <Table responsive

                            >
                                <tbody
                                    className='trace-table-body'
                                >
                                    {examDates.map(a => {

                                        return (
                                            <tr
                                                className={`trace-table-item text-left ${exam && exam.ExaminationID === a.ExaminationID ? 'trace-selected-item' : ''}`}
                                                onClick={() => setExam(a)}
                                            >
                                                <td>{moment(a.ExaminationDate).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </Table>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={() => handleImagingReview()}
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '5px'
                                }}
                            >
                                <img
                                    src={Logo}
                                    height={60}
                                    width={60}

                                />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ReviewOptions;
