import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { getCervixExaminationById } from '../../../redux/actions/data.action';
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS } from '../../../redux/actions/types';

const CaseReview = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { patientDetails, lastExamination } = useSelector(({ data }) => data)
    const { viewOption } = useSelector(({ ui }) => ui)

    const [examDetails, setExamDetails] = useState({});


    const handleNext = (e) => {
        e.preventDefault()
        if (viewOption === 'enter') {
            dispatch({ type: CLEAR_EXAMINATION_DETAILS })
        }
        navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination' : '/dashboard/viewPatient/examination'}`)
    }


    const handleGetExamination = (id) => {

        dispatch(getCervixExaminationById(id))
            .then(res => {
                if (res) {
                    setExamDetails(res)
                }
            })

    }




    useEffect(() => {
        if (lastExamination) {
            handleGetExamination(lastExamination.ExaminationID)
        }
    }, [lastExamination])

    console.log(examDetails, 'EXAM DETAILS')



    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">Examination Details</h4>
            </div>
            <br />
            <div className='m-3'>
                <div className="bordered form-body pr-3 pl-3 ">
                    <h3 className="card-title text-center bordered-title  ">Patient's Case Review</h3>
                    <Row>
                        <Col xs="12" sm="6" className="p-3">
                            <div className="padRight20">
                                <div className='row txtLeft'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Name</label>
                                            <input type="text" id="firstName" className="form-control" placeholder=""
                                                value={`${patientDetails.Forename} ${patientDetails.Surname}`}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Management Plan</label>
                                            <input type="text" id="firstName" className="form-control" placeholder=""
                                                value={examDetails?.tbl_CervixManagement?.ManagementPlan}
                                            />
                                        </div>
                                    </div>
                                    <div className='clr'></div>
                                </div>

                                <div className='clr'></div>
                                <div className='row txtLeft'>
                                    <div className='col-md-12'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Examination Summary</label>
                                            <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                                                value={examDetails?.tbl_cervixexaminationdetail?.DetailsLetterSummary.replace('...', '').trim()}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row txtLeft'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Treatment</label>
                                            <input type="text" id="firstName" className="form-control" placeholder=""
                                                value={examDetails?.tbl_cervixtreatment?.Treatment !== 'None' ? examDetails?.tbl_cervixtreatment?.Treatment : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Treatment Status</label>
                                            <input type="text" id="firstName" className="form-control" placeholder=""
                                                value={examDetails?.tbl_CervixManagement?.TreatmentStatus}
                                            />
                                        </div>
                                    </div>
                                    <div className='clr'></div>
                                </div>
                                <div className='row txtLeft'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Treatment Date</label>
                                            <input type="text" id="firstName" className="form-control" placeholder=""
                                                value={examDetails?.tbl_cervixtreatment?.Treatment !== 'None' ? moment(examDetails?.tbl_CervixManagement?.TreatmentDate).format('DD/MM/YYYY') : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Clinic Date</label>
                                            <input type="text" id="firstName" className="form-control" placeholder=""
                                                value={examDetails.Title}
                                            />
                                        </div>
                                    </div>
                                    <div className='clr'></div>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" sm="6" className="p-3">
                            <div className="padRight20">
                                <div className='row txtLeft'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Last Examination</label>
                                            <input type="text" id="firstName" className="form-control" placeholder=""
                                                value={moment(lastExamination.ExaminationDate).format('DD/MM/YYYY')}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {/* <div className="form-group">
                                                <label className="control-label">Treatment Status</label>
                                                <input type="text" id="firstName" className="form-control" placeholder=""
                                                    value={examDetails.Title}
                                                />
                                            </div> */}
                                    </div>
                                    <div className='clr'></div>
                                </div>
                                <div className='row txtLeft'>


                                    <div className='clr'></div>
                                    <div className='col-md-12'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Biopsy</label>
                                            <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                                                value={examDetails.SpecifyAbnormality}
                                            />
                                        </div>
                                    </div>
                                    <div className='clr'></div>
                                    <div className='col-md-12'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Smear</label>
                                            <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                                                value={examDetails.SpecifyAbnormality}
                                            />
                                        </div>
                                    </div>
                                    <div className='clr'></div>
                                    <div className='col-md-12'>
                                        <div className="form-group">
                                            <label className="control-label text-blue">Additional Information</label>
                                            <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                                                value={examDetails.SpecifyAbnormality}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col>

                        <div className='clr'></div>
                    </Row>
                </div>
                <br />
                <div className="text-xs-center fR">
                    <Button className="btn btnIri marRight20" onClick={handleNext}>Next</Button>
                    <Button type="submit" className="btn btnIri marRight20"
                        onClick={() => navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)}
                    >Exit</Button>
                </div>
            </div>
        </>
    )
}

export default CaseReview;
