import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CLEAR_EXAMINATION, CLEAR_EXAMINATIONS, CLEAR_EXAMINATION_DETAILS, CLEAR_PATIENT_DETAILS, CLEAR_PERSONAL_REFERRAL_DETAILS, CLEAR_REFERRAL_REASONS, CLOSE_MODAL, OPEN_MODAL, SET_EXAMINATION, SET_EXAMINATIONS, SET_LAST_EXAMINATION, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PEROSNAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS } from '../../redux/actions/types';
import Table from 'react-bootstrap/Table';
import { getCervixExaminations, getPersonalReferralDetailsById, updateInUseBy } from '../../redux/actions/data.action';
import moment from 'moment';
import CustomModal from '../../component/Modals/CustomModal';


const EnterOptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { viewOption, modal } = useSelector(({ ui }) => ui)
    const { patientDetails, examination, personalReferralDetails, managementDetails } = useSelector(({ data }) => data)
    const [exam, setExam] = useState(null);
    const [examDates, setExamDates] = useState([])

    const handleExit = () => {
        dispatch(updateInUseBy('remove'))
        dispatch({ type: CLEAR_PATIENT_DETAILS });
        dispatch({ type: CLEAR_PERSONAL_REFERRAL_DETAILS });
        dispatch({ type: CLEAR_REFERRAL_REASONS });

        localStorage.removeItem("formDetails")
        navigate('/dashboard/home')
    }

    const handleEnterExamination = () => {


        if (!personalReferralDetails) {
            return dispatch({ type: OPEN_MODAL, payload: 'noAppointmentModal' })
        }


        const today = moment().format('YYYY-MM-DD'); // Get today's date in the same format as ExaminationDate
        let isToday = examDates.find(item => (moment(item.ExaminationDate).isSame(today, 'day')))

        if (isToday) {
            dispatch({ type: OPEN_MODAL, payload: 'sameexamtoday' })
            return
        } else {








            if ((exam && examDates.length !== 0)) {



                let ind = examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID)
                dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                dispatch({ type: SET_EXAMINATION, payload: exam })
                dispatch({ type: SET_LAST_EXAMINATION, payload: examDates[ind - 1 === -1 ? 0 : ind - 1] })
                navigate('/dashboard/patient/casereview')
            } else {





                // dispatch({ type: CLEAR_EXAMINATIONS })
                if (exam) {
                    dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                    dispatch({ type: SET_EXAMINATION, payload: exam })
                }
                navigate('/dashboard/patient/examination')
            }
        }





        /*    // dispatch({ type: CLEAR_EXAMINATIONS })
   
           dispatch({ type: CLEAR_EXAMINATIONS })
           dispatch({ type: CLEAR_EXAMINATION_DETAILS })
   
           // dispatch({ type: SET_EXAMINATIONS, payload: examDates })
           // dispatch({ type: SET_EXAMINATION, payload: exam })
           navigate('/dashboard/patient/examinationDetails') */

    }




    const handleExaminationDetails = (SystemID) => {
        dispatch(getCervixExaminations({ SystemID }))
            .then(res => {
                if (res && res.length !== 0) {
                    setExamDates(res)
                    if (!examination || (examination && !examination.ExaminationID)) {
                        setExam(res[res.length - 1])
                    } else {
                        let ind = res.find(a => (a.ExaminationID === examination.ExaminationID || (exam && a.ExaminationID === exam.ExaminationID)));
                        if (ind) {
                            setExam(ind)
                        }
                    }
                } else {
                    setExamDates([])
                    setExam(null)
                }
            })
    }


    const handleTreatmentResults = (prop) => {
        let { tbl_CervixManagement } = exam;



        dispatch({ type: SET_EXAMINATION, payload: exam })



        if (prop === 'treatment' && tbl_CervixManagement && tbl_CervixManagement.ManagementPlan === 'Treatment') {
            navigate('/dashboard/patient/treatment')
            return
        }


        if (prop === 'results' && tbl_CervixManagement && tbl_CervixManagement.ManagementPlan === 'Treatment') {
            navigate('/dashboard/patient/results')
            return
        }


        navigate('/dashboard/patient/management')

    }

    useEffect(() => {
        if (patientDetails && patientDetails.SystemID) {
            handleExaminationDetails(patientDetails.SystemID)
        } else {
            navigate('/dashboard/home')
        }


        if (viewOption === 'review') {
            navigate('/dashboard/viewPatient')
        }


    }, [])



    useEffect(() => {
        if (!modal) {
            handleExaminationDetails(patientDetails.SystemID)
        }
    }, [modal])




    return (
        <>
            <CustomModal
                size="lg"
                open={modal === 'sameexamtoday'}
                title="Duplication Error"
                onOkay={() => {
                    dispatch({ type: CLOSE_MODAL })
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <i className="ti-alert  text-warning mr-3 fa-2x"></i>
                        <div className='text-center'>
                            <span style={{ fontSize: '13px' }}>An examination has been entered for this patient today!</span><br />
                            <span style={{ fontSize: '13px' }}>If you want to amend the examination please contact the COMPUSCOPE Administrator.</span>

                        </div>
                    </div>
                </div>

            </CustomModal>

            <div className="card-header bg-iri w-100">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end'
                    }}
                >
                    <h4 className="text-white">Enter Options</h4>
                </div>
            </div>
            <div className="card-body">

                <Row>
                    <Col sm="7">
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                                onClick={() => navigate('/dashboard/patient/enterPatient')}
                            >Personal</Button></Col> <Col className='text-left'><h5 className='text-primary'>Personal Details</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                disabled={!patientDetails.SystemID}
                                onClick={() => handleEnterExamination()}
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}>Examination</Button></Col> <Col className='text-left'><h5 className='text-primary'>New Examination / Episode</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                disabled={!exam}
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                                onClick={() => handleTreatmentResults('treatment')}
                            >Treatment</Button></Col> <Col className='text-left'><h5 className='text-primary'>Treatment Details</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                disabled={!exam}
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                                onClick={() => handleTreatmentResults('results')}
                            >Results</Button></Col> <Col className='text-left'><h5 className='text-primary'>Biopsy/Smear/Swab Results</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                disabled={!exam}
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                                onClick={() => handleTreatmentResults('appointment')}
                            >Appointment</Button></Col> <Col className='text-left'><h5 className='text-primary'>Treatment/Clinic Appointments</h5></Col>
                        </Row>
                        <Row
                            className='m-3'
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Col> <Button
                                onClick={() => handleExit()}
                                // className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                            >Exit</Button></Col> <Col className='text-left'><h5 className='text-primary'>Exit Enter</h5></Col>
                        </Row>
                    </Col>
                    <Col sm="5" style={{ width: '100%', flexGrow: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingRight: 30, paddingLeft: 30 }}>
                        <p className='pr-5 pl-5 p-10 w-100 text-center text-primary font-medium'>Specify the Examination Date for the Biopsy/Smear/Swab and any Treatment results: </p>
                        <div
                            className='trace-table'
                            style={{
                                minHeight: 200,
                                overflowX: 'hidden'
                            }}
                        >
                            <Table responsive

                            >
                                <tbody
                                    className='trace-table-body'
                                >
                                    {examDates.map((a, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className={`trace-table-item text-left ${exam && exam.ExaminationID === a.ExaminationID ? 'trace-selected-item' : ''}`}
                                                onClick={() => { setExam(a); dispatch({ type: SET_EXAMINATION, payload: a }) }}
                                            >
                                                <td>{moment(a.ExaminationDate).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default EnterOptions;
