import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Input, Button, Form, FormGroup, Label, FormFeedback, FormText } from 'reactstrap';
import moment from 'moment';
import { cancelReasonsData, ethnicOriginData, maritalStatusData } from '../../../Utils/commonData';
import { getPatientDetailsById, getPersonalReferralDetailsById, getUserDetails, updatePatientDetails, updatePersonalReferralDetailsById } from '../../../redux/actions/data.action';
import { CLEAR_PATIENT_DETAILS, CLEAR_PERSONAL_REFERRAL_DETAILS, CLOSE_MODAL, OPEN_MODAL, SET_EDITED, SET_PATIENT_DETAILS, SET_PEROSNAL_REFERRAL_DETAILS } from '../../../redux/actions/types';
import CustomModal from '../../../component/Modals/CustomModal';
import OptionsFrameHOC from '../../../component/Forms/OptionsFrameHOC';
import { capitalizeFirstLetter, isDateAfter, isDateBefore, isTodayOrFutureDate, validatePatientDetails, validateReferralDetails } from '../../../Utils/validators';


const One = (props) => {
  const { modal } = useSelector(({ ui }) => ui)
  const { patientDetails: patient, personalReferralDetails: referral } = useSelector(({ data }) => data)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { viewOption, edited } = useSelector(({ ui }) => ui)
  const [clinics, setClinics] = useState([])
  const [attendance, setAttendance] = useState(null);
  const [reason, setReason] = useState(null);
  const [errors, setErrors] = useState({});
  const [isDischarge, setIsDischarge] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    let err = {}
    let referralError = validateReferralDetails(referral).errors;
    let referralValid = validateReferralDetails(referral).valid;
    let patientValid = validatePatientDetails(patient).valid;
    let patientError = validatePatientDetails(patient).errors;
    if ((!referralValid || !patientValid)) {
      err = { ...err, ...referralError, ...patientError }
      // return
    }

    if (isDateBefore(referral.AppointmentDateCervix, moment(referral.ReferralDateCervix).subtract(1, 'd'))) {
      err = { ...err, AppointmentDateCervix: "Appointment Date can not be before letter's date!" }
      // return
    }

    if (Object.keys(err).length !== 0) {
      setErrors(err)
      return
    }



    if (referral.AppointmentCancellationReason) {

    }



    navigate(`${viewOption === 'enter' ? '/dashboard/patient/referralDetails' : '/dashboard/viewPatient/referralDetails'}`)

  };



  const handleChangePatient = prop => event => {
    let formDetails = localStorage.getItem('formDetails');



    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let val = String(prop).includes('date') ? event.target.value : capitalizeFirstLetter(event.target.value)

    dispatch({ type: SET_PATIENT_DETAILS, payload: { ...patient, [prop]: val } })
    // setPatient({ ...patient, [prop]: event.target.value })
    localStorage.setItem('formDetails', JSON.stringify({ ...JSON.parse(formDetails ? formDetails : null), patient: { ...patient, [prop]: val }, referral }))

    dispatch({ type: SET_EDITED, payload: 'personal' })
  }

  const handleChangeReferral = prop => event => {
    let formDetails = localStorage.getItem('formDetails');
    let newErrors = errors;

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }
    let val = String(prop).includes('date') ? event.target.value : capitalizeFirstLetter(event.target.value)


    let newVal = { ...referral, [prop]: val };


    delete newErrors[prop];
    setErrors(newErrors)


    if (prop === 'ReferralDateCervix' || prop === 'ReferralReceivedDateCervix' || prop === 'AppointmentDateCervix') {
      // let { valid, errors } = validateReferralDetails({ ...referral, [prop]: event.target.value })
      // newVal = { ...referral, [prop]: event.target.value };


      // if (!valid) {
      //   setErrors({ [prop]: errors[prop] })
      // }


      // if (prop === 'AppointmentDateCervix' && isDateBefore(event.target.value, moment(referral.ReferralDateCervix).subtract(1, 'd'))) {
      //   setErrors({ [prop]: "Appointment Date can not be before letter's date!" })
      //   return
      // }

      if ((prop === 'ReferralDateCervix' && referral.ReferralID)) {
        dispatch({ type: OPEN_MODAL, payload: 'Rereferral' })
      }



    } else {


    }



    if (prop === 'AppointmentDateCervix') {
      setAttendance('Scheduled')
      newVal = { ...newVal, AppointmentCancellationReason: '' }
    }

    dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: newVal })
    localStorage.setItem('formDetails', JSON.stringify({ ...JSON.parse(formDetails), referral: newVal, patient }))
    // setReferral({ ...referral, [prop]: event.target.value })
    dispatch({ type: SET_EDITED, payload: 'personal' })
  }

  const handleAttendance = (val) => {

    if (referral.AppointmentDateCervix && !isTodayOrFutureDate(referral.AppointmentDateCervix)) {

      return
    }


    setAttendance(val)
    if (val != 'Scheduled') {
      let newVal = { ...referral, AppointmentDateCervix: null, reasonid: null }

      if (val === 'H Cancel') {
        newVal = { ...newVal, AppointmentCancellationReason: 'Cancelled by clinic' }
      } else {
        let cancelreason = cancelReasonsData.find(a => a.value == referral.AppointmentCancellationReason)
        if (cancelreason) {
          setReason(cancelreason)
        }
        dispatch({ type: OPEN_MODAL, payload: 'attendance' });

      }


      dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: newVal })
    } else {
      let formDetails = localStorage.getItem('formDetails');
      let frm = formDetails ? JSON.parse(formDetails) : null;
      let newVal = { ...referral, AppointmentDateCervix: frm?.referral?.AppointmentDateCervix, AppointmentCancellationReason: null }



      dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: newVal })
      localStorage.setItem('formDetails', JSON.stringify({ ...JSON.parse(formDetails), referral: newVal, patient }))
    }
    dispatch({ type: SET_EDITED, payload: 'personal' })
  }



  const handleCancel = (e) => {
    e.preventDefault();
    if (edited.find(a => a === 'personal')) {
      dispatch({ type: OPEN_MODAL, payload: 'save_details' })
      // return

    }

    if (!patient.SystemID) {
      dispatch({ type: CLEAR_PATIENT_DETAILS })
    }

    // setExamDetails({})
    // dispatch({type: CLEAR_EXAMINATION})
    navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
  }

  const handleDischarge = async (e) => {
    let { ReferralID, AppointmentCancellationReason } = referral;
    let { SystemID } = patient;
    await dispatch(updatePatientDetails({ SystemID, PatientDischarged: true }))
    await dispatch(updatePersonalReferralDetailsById({ ReferralID, dtPatientDischargeDate: new Date(), AppointmentCancellationReason }))
    let patientDetails = await dispatch(getPatientDetailsById(SystemID))
    await dispatch(getPersonalReferralDetailsById(ReferralID))
      .then((a) => {
        dispatch({ type: SET_PATIENT_DETAILS, payload: { ...patient, ...patientDetails } })
        dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: { ...referral, ...a.data } })
      })


  }

  const handleReinstate = async (e) => {
    // let { ReferralID } = referral;
    let { SystemID } = patient;
    // e.preventDefault();
    // await dispatch(updatePersonalReferralDetailsById({ReferralID, dtPatientDischargeDate: null}))  
    await dispatch(updatePatientDetails({ SystemID, PatientDischarged: false }))
    let patientDetails = await dispatch(getPatientDetailsById(SystemID))
    dispatch({ type: SET_PATIENT_DETAILS, payload: { ...patient, ...patientDetails } })
  }


  const handleGetClinics = () => {

    dispatch(getUserDetails({ Access: 2 }))
      .then(res => {

        if (res) {
          setClinics(res)
        }
      })
  }

  const handleInitialAttendance = () => {
    if (referral.AppointmentCancellationReason || !referral.AppointmentDateCervix) {
      let oldAttendance = cancelReasonsData.find(a => a.value == referral.AppointmentCancellationReason);
      if (oldAttendance) {
        setAttendance(oldAttendance.key);
        setReason(oldAttendance)
      }
    } else {
      setAttendance('Scheduled')
      dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: { ...referral, AppointmentCancellationReason: '' } })

    }
  }





  useEffect(() => {
    handleGetClinics()
  }, [])

  useEffect(() => {
    handleInitialAttendance()
  }, [])

  console.log(referral, 'referral')

  return (
    <>
      <CustomModal
        open={(modal === 'discharge' || modal === 'reinstate')}
        title="Patient Discharge"
        onNo={() => {
          setIsDischarge(false)
        }}
        onYes={() => {
          if (modal === 'discharge') {
            if (!isDischarge) {
              setIsDischarge(true)
            } else {
              handleDischarge()
              dispatch({ type: OPEN_MODAL });
              setIsDischarge(false)
            }
          } else {
            handleReinstate()
            dispatch({ type: OPEN_MODAL });
          }
        }}

      >
        <div className='text-left pl-5 pr-5'>
          {modal === 'discharge' ? !isDischarge ?
            <>
              <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <i className="ti-help-alt text-success mr-3 fa-2x"></i> {patient.HospitalNumber} {patient.Forename} {patient.Surname}  </span>
              <span className='ml-5 pl-3'>Do you wish to discharge her?</span>
            </>
            :
            <span >
              Is the discharge date today {moment().format('DD MMM YYYY')} ?</span> :
            <>
              <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <i className="ti-help-alt text-success mr-3 fa-2x"></i> {patient.HospitalNumber} {patient.Forename} {patient.Surname}  </span>
              <span className='ml-5 pl-3'>Do you wish to reinstate her?</span>
            </>
          }
        </div>

      </CustomModal>

      <CustomModal
        title="Select a reason for the cancellation"
        open={modal === 'attendance' && (attendance === 'P Cancel' || attendance === 'DNA')}
        onClose={() => {
          setReason(null)
          handleInitialAttendance()
        }}
        onOkay={() => {
          let formDetails = localStorage.getItem('formDetails')
          // let newVal = {...referralReasons, referralsmearhpv: false, referralsmearcervix: "No Smear", referralreasonlettersummarycervix: reasonLetterSummary(referralReasons.referralindicationcervix, false)}
          let newVal = { ...referral, AppointmentCancellationReason: reason && reason.value, reasonid: reason && reason.id }
          dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: newVal })
          localStorage.setItem('formDetails', JSON.stringify({ ...JSON.parse(formDetails), referral: newVal }))
          setReason(null)
          dispatch({ type: SET_EDITED, payload: 'personal' })
          dispatch({ type: OPEN_MODAL });

        }}
      >
        <OptionsFrameHOC
          title="Reason for Cancellation"
          titleClass="text-center text-primary"
          containerClass="p-1 pl-5"
        >
          {/* {attendance === 'P Cancel' ?  */}
          <>
            {cancelReasonsData.filter(a => a.key === attendance).map((a, index) => {
              return (
                <div key={index}>
                  <input type="radio" id={a.value}
                    checked={reason && reason.value === a.value} name="pcancel_radio" className="" onChange={() => setReason(a)} />
                  <label className="" htmlFor={a.value}>{a.value}</label>
                  <div className='clearfix'></div>
                </div>
              )
            })}
          </>
          {/* } */}
        </OptionsFrameHOC>
      </CustomModal>

      <CustomModal
        open={modal === 'Rereferral'}
        title="New Referrals"
        onNo={() => {
          dispatch({ type: CLOSE_MODAL })
        }}
        onYes={() => {
          dispatch({ type: CLEAR_PERSONAL_REFERRAL_DETAILS })
          dispatch({ type: CLOSE_MODAL })

        }}
        onCancel={() => {
          dispatch({ type: CLOSE_MODAL })
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="ti-help-alt  text-info mr-3 fa-2x"></i>
            <span style={{ fontSize: '13px' }}>You have changed the referral date - is this a re-referral?</span>
          </div>
        </div>

      </CustomModal>

      <Form
        id="formValid"
        noValidate
        onSubmit={handleSubmit} onReset={handleCancel}
      >
        <div className="text-left" style={{ width: '100%' }}>
          <div className="d-flex p-t-20" style={{ width: '100%' }}>
            <div className="col-md-4  mb-5" style={{ display: 'flex', width: '100%' }}>
              <div className='pl-3 pr-3  bordered'>
                <h3 className="card-title txtLeft bordered-title">Patients Demographic</h3>
                <div className='row'>
                  <div className="form-input col-md-3 text-left">
                    <label className="control-label text-blue">Title</label>
                    <input type="text" id="title" className="form-control" placeholder="" required
                      value={patient.Title}
                      onChange={handleChangePatient('Title')
                      }
                    />
                  </div>
                  <div className="form-input col-md-9">
                    <Label for="firstName" >
                      Forename <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text" id="firstName" className="form-control" placeholder="" required
                      value={patient.Forename}
                      onChange={handleChangePatient('Forename')}
                      invalid={errors.Forename}
                    />
                    <FormFeedback>
                      {errors.Forename}
                    </FormFeedback>
                  </div>
                </div>
                <div className="form-input">
                  <Label for="firstName" >
                    Surname <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text" id="Surname" className="form-control" placeholder="" required
                    value={patient.Surname}
                    onChange={handleChangePatient('Surname')}
                    invalid={errors.Surname}
                  />
                  <FormFeedback>
                    {errors.Surname}
                  </FormFeedback>
                </div>
                <div className="form-input">
                  <label className="control-label text-blue">Maidenname</label>
                  <input type="text" id="MaidenName" className="form-control" placeholder=""
                    value={patient.MaidenName}
                    onChange={handleChangePatient('MaidenName')}
                  />
                </div>
                <div className='form-input'>
                  <Label for="letterdate">
                    Date of Birth <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="date" id="DateOfBirth" name="DateOfBirth" className="form-control" placeholder="" required
                    value={patient.DateOfBirth ? moment(patient.DateOfBirth).format('YYYY-MM-DD') : null}
                    onChange={handleChangePatient('DateOfBirth')}
                    invalid={errors.DateOfBirth}
                  />
                  <FormFeedback>
                    {errors.DateOfBirth}
                  </FormFeedback>
                </div>
                <div className="form-input">
                  <label className="control-label text-blue">NHS Number</label>
                  <input type="text" id="NHSnumber" className="form-control" placeholder=""
                    value={patient.NHSnumber}
                    onChange={handleChangePatient('NHSnumber')}
                  />
                </div>
                <div className="form-input">
                  <label className="control-label text-blue">Address</label>
                  <input type="text" id="Address" className="form-control" placeholder=""
                    value={patient.Address}
                    onChange={handleChangePatient('Address')}
                  />
                </div>
                <Row
                  className='row'
                >
                  <Col>
                    <div className="form-input">
                      <label className="control-label text-blue">Ethnic Origin</label>
                      <Input
                        type="select"
                        id="ethnic-origin" className="form-control" placeholder=""
                        value={patient.EthnicOrigin}
                        onChange={handleChangePatient('EthnicOrigin')}
                      >
                        {Object.entries(ethnicOriginData.reduce((group, item) => {
                          group[item.group] = group[item.group] ?? { data: [], group: item.group, index: item.index }
                          group[item.group].data.push(item);
                          return group;
                        }, {})).sort((a, b) => a[1].index - b[1].index)
                          .map((a, index) => {
                            return (< >
                              <option key={index} disabled className='text-danger m-1 mb-2' style={{ paddingLeft: '10px', fontSize: 10 }}>
                                {a[0]}
                              </option>
                              {a[1].data.map((ab, index) => {
                                return (
                                  <option key={index} value={ab.value} style={{ fontSize: 13 }}>{ab.title}</option>
                                )
                              })}
                            </>)
                          })}
                      </Input>

                    </div>
                  </Col>
                  <Col>
                    <div className="form-input">
                      <label className="control-label text-blue">Marital Status</label>
                      <select name="marital-status" id="marital-status" className="form-control"
                        value={patient.MaritalStatus}
                        onChange={handleChangePatient('MaritalStatus')}

                      >
                        <option value={null}></option>
                        {maritalStatusData.map(a => {
                          return (
                            <option value={a.value}>{a.title}</option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-input">
                      <label className="control-label  text-blue">Occupation</label>
                      <input type="text" id="Occupation" className="form-control" placeholder=""
                        value={patient.Occupation}
                        onChange={handleChangePatient('Occupation')}
                      />
                    </div>
                  </Col>
                  <Col>

                    <div className="form-input">
                      <label className="control-label  text-blue">Partner's Occupation</label>
                      <input type="text" id="firstName" className="form-control" placeholder=""
                        value={patient.PartnerOccupation}
                        onChange={handleChangePatient('PartnerOccupation')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>

                    <div className="form-input">
                      <label className="control-label text-blue">Home No</label>
                      <input type="text" id="firstName" className="form-control" placeholder=""
                        value={patient.PhoneNumber}
                        onChange={handleChangePatient('PhoneNumber')}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-input">
                      <label className="control-label text-blue">Mobile No</label>
                      <input type="text" id="firstName" className="form-control" placeholder=""
                        value={patient.MobilePhoneNumber}
                        onChange={handleChangePatient('MobilePhoneNumber')}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="form-input">
                  <label className="control-label text-blue">Daytime No</label>
                  <input type="text" id="daytime" className="form-control" placeholder=""
                    value={patient.OtherPhoneNumber}
                    onChange={handleChangePatient('OtherPhoneNumber')}
                  />
                </div>

              </div>
            </div>
            {/* //END OF COLUMN 1 */}
            <div className='col-md-8 mb-5 pl-2 pr-2 ' style={{ display: 'flex', width: '100%' }}  >
              <div className='bordered pl-2 pr-2' style={{ width: '100%' }} >
                <h3 className="card-title  bordered-title">Patients Referral Details</h3>
                <div className="row pr-5 pl-5">
                  <label className="control-label disabled col-md-6 pt-3  text-right">First Appointment Date</label>
                  <input
                    type="date"
                    id="firstAppointmentDate" name="firstAppointmentDate" className="form-control col-md-5 ml-2" placeholder=""
                    value={referral.FirstAppointmentDateCervix ? moment(referral.FirstAppointmentDateCervix).format('YYYY-MM-DD') : ''}
                    onChange={() => console.log('FIRST APPOINTMENT')}
                    disabled
                  />
                </div>
                <br />
                <div className='row'>
                  <div className="col-md-6 mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'space-around' }}>
                    <div className='bordered pl-2 pr-2'>
                      <h3 className="card-title bordered-title">Referral Date</h3>
                      <div className='row'>
                        <FormGroup className='col-md-6'>
                          <Label for="letterdate">
                            Letter Date
                          </Label>
                          <Input
                            type="date" id="letterdate" name="referraldate" className="form-control" placeholder="Letter Date"
                            value={referral.ReferralDateCervix ? moment(referral.ReferralDateCervix).format('YYYY-MM-DD') : ''}
                            onChange={handleChangeReferral('ReferralDateCervix')}
                            invalid={errors.ReferralDateCervix}
                          />
                          <FormFeedback>
                            {errors.ReferralDateCervix}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup className='col-md-6'>
                          <Label className="control-label text-success ">
                            Received Date
                          </Label>
                          <Input
                            type="date" id="receivedDate" name="receivedDate" className="form-control" placeholder="Received Date"
                            value={referral.ReferralReceivedDateCervix ? moment(referral.ReferralReceivedDateCervix).format('YYYY-MM-DD') : ''}
                            onChange={handleChangeReferral('ReferralReceivedDateCervix')}
                            invalid={errors.ReferralReceivedDateCervix}


                          />
                          <FormFeedback>
                            {errors.ReferralReceivedDateCervix}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className='bordered pl-2 pr-2'>
                      <h3 className="card-title bordered-title">Source of Referral</h3>
                      <div className='row pl-2'>
                        <div className='col-md-6 '>
                          <div className="custom-radio">
                            <input type="radio" id="G.P."
                              value="G.P." checked={referral.ReferringCentre === 'G.P.'} name="styled_radio" required="" className="" onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="G.P.">GP</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="F.P. Clinic" value="F.P. Clinic" checked={referral.ReferringCentre === 'F.P. Clinic'} name="styled_radio" required="" className="" onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="F.P. Clinic">F.P. Clinic</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="Direct Referral" value="Direct Referral" name="styled_radio" required="" className="" checked={referral.ReferringCentre === 'Direct Referral'} onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="Direct Referral">Direct Referral</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="G.U. Clinic" value="G.U. Clinic" name="styled_radio" required="" className="" checked={referral.ReferringCentre === 'G.U. Clinic'} onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="G.U. Clinic">G.U. Clinic</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="Gynaecology Clinic" value="Gynaecology Clinic" checked={referral.ReferringCentre === 'Gynaecology Clinic'} name="styled_radio" required="" className="" onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="Gynaecology Clinic">Gynaecology Clinic</label>
                            <div className='clearfix'></div><br />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className="custom-radio">
                            <input type="radio" id="G.P. Direct" value="G.P. Direct" name="styled_radio" required="" className="" checked={referral.ReferringCentre === 'G.P. Direct'} onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="G.P. Direct" >G.P. Direct</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="F.P. Clinic Direct" value="F.P. Clinic Direct" name="styled_radio" required="" className="" checked={referral.ReferringCentre === 'F.P. Clinic Direct'} onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="F.P. Clinic Direct">F.P. Clinic Direct</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="Follow-up" value="Follow-up" name="styled_radio" required="" className="" checked={referral.ReferringCentre === 'Follow-up'} onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="Follow-up">Follow-up</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="Private" value="Private" name="styled_radio" required="" className="" checked={referral.ReferringCentre === 'Private'} onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="Private">Private</label>
                            <div className='clearfix'></div><br />

                            <input type="radio" id="Others" value="Others" name="styled_radio" required="" className="" checked={referral.ReferringCentre === 'Others'} onChange={handleChangeReferral('ReferringCentre')} />
                            <label className="text-12  text-blue" htmlFor="Others">Others</label>
                            <div className='clearfix'></div><br />
                            {referral.ReferringCentre === 'Others' && <input type="text" id="ReferringCentreOther" className="form-control" placeholder="" value={referral.ReferringCentreOther} onChange={handleChangeReferral('ReferringCentreOther')} />}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className='bordered pl-2 pr-2'>
                      <h3 className="card-title bordered-title">Referrer Details</h3>
                      <div className="form-group">
                        <label className="control-label text-blue">Referrer Name</label>
                        <input type="text" id="firstName" className="form-control" placeholder=""
                          value={referral.ReferrerNameCervix}
                          onChange={handleChangeReferral('ReferrerNameCervix')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label text-blue">Referrer Address</label>
                        <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                          value={referral.ReferrerAddressCervix}
                          onChange={handleChangeReferral('ReferrerAddressCervix')}
                        />
                      </div>
                    </div>
                    <br />

                  </div>

                  {/* //END OF COLUMN 2 */}
                  <div className="col-md-6 mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'space-around' }}>

                    <div className='bordered pl-2 pr-2'>
                      <h3 className="card-title bordered-title">Appointment Date</h3>

                      {!patient.PatientDischarged ?
                        <FormGroup>
                          <Label>
                            Date/Time<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            id="appointmentdate" name="appointmentdate" className="form-control" placeholder=""
                            value={referral.AppointmentDateCervix ? moment(referral.AppointmentDateCervix).format('YYYY-MM-DD') : ''}
                            onChange={handleChangeReferral('AppointmentDateCervix')}
                            invalid={errors.AppointmentDateCervix}


                          />
                          <FormFeedback>
                            {errors.AppointmentDateCervix}
                          </FormFeedback>
                        </FormGroup>
                        :
                        <FormGroup>
                          <Label>
                            Discharge Date<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            id="dtPatientDischargeDate" name="dtPatientDischargeDate" className="form-control" placeholder=""
                            value={referral.dtPatientDischargeDate ? moment(referral.dtPatientDischargeDate).format('YYYY-MM-DD') : ''}
                            onChange={handleChangeReferral('dtPatientDischargeDate')}
                            invalid={errors.dtPatientDischargeDate}
                          />
                          <FormFeedback>
                            {errors.AppointmentDateCervix}
                          </FormFeedback>
                        </FormGroup>
                      }
                      <div className='bordered '>
                        <div className="card-title bordered-title" style={{ marginLeft: 3, fontSize: '14px' }}></div>

                        <div className='clearfix bordered'></div>
                        <div className='row pl-2'>
                          <div className='col-md-3 pl-3' style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="radio" name="cancellation_radio" className=""
                              id="Scheduled"
                              checked={attendance === 'Scheduled'}
                              // onClick={() => handleAttendance('Scheduled')}
                              onChange={() => handleAttendance('Scheduled')}
                            />
                            <label className="mt-2 text-12 text-blue" htmlFor='Scheduled' >Scheduled</label>
                          </div>
                          <div className='col-md-3 pl-3' style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="radio" name="cancellation_radio" className=""
                              id="H Cancel"
                              checked={attendance === 'H Cancel'}
                              // onClick={() => handleAttendance('H Cancel')}
                              onChange={() => handleAttendance('H Cancel')}
                            />
                            <label className="mt-2 text-12 text-blue" htmlFor='H Cancel'>(H)cancel</label>
                          </div>
                          <div className='col-md-3 pl-3' style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="radio" name="cancellation_radio" className=""
                              checked={attendance === 'P Cancel'}
                              onChange={() => handleAttendance('P Cancel')}
                              id="P Cancel"
                            />
                            <label className=" mt-2 text-blue"
                              htmlFor='P Cancel'
                            >(P)Cancel</label>
                          </div>
                          <div className='col-md-3  pl-3' style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="radio" name="cancellation_radio" className=""
                              checked={attendance === 'DNA'}
                              onChange={() => handleAttendance('DNA')}
                              id="dna"
                            />
                            <label className=" mt-2 text-blue" style={{ fontSize: 12 }} htmlFor='dna'>DNA</label>
                            <br />
                          </div>
                        </div>
                        <div className='clearfix bordered'></div>
                        <div className="col-md-12 text-left" >
                          <br />
                          <label className="control-label text-left col-md-4 text-blue" style={{ fontSize: 12 }}>Cancel Reason</label>
                          <input type="text" id="AppointmentCancellationReason" style={{ fontSize: 12 }} className="form-control col-md-8 text-left" placeholder=""
                            value={referral.AppointmentCancellationReason}
                            onChange={() => console.log('appointmentcancellation')}
                          />
                          <div className='clr'></div>
                        </div>
                        <br />
                      </div>
                      <br />
                      <div className="col-md-12 text-left" >
                        <label className="control-label text-left col-md-4 text-blue" style={{ fontSize: 12 }}>Clinic</label>
                        <select name="AppointmentNameCervix" id="AppointmentNameCervix" style={{ fontSize: 12 }} className="form-control  col-md-8 "
                          value={referral.AppointmentNameCervix}
                          onChange={handleChangeReferral('AppointmentNameCervix')}

                        >
                          <option value={null}>Select</option>
                          {clinics.length !== 0 && clinics.map(a => {
                            return (
                              <option value={a.iUserID}>{a.Name}</option>
                            )
                          })}
                        </select>
                        <div className='clr'></div>
                      </div>
                      <br />
                    </div>
                    <br />

                    <div className="bordered pl-2 pr-2">
                      <h3 className="card-title txtLeft bordered-title">GP's Details</h3>

                      <div className="form-input">
                        <label className="control-label text-blue">GP Name</label>
                        <input type="text" id="firstName" className="form-control" placeholder=""
                          value={referral.GPName}
                          onChange={handleChangeReferral('GPName')}
                        />
                      </div>

                      <div className="form-input">
                        <label className="control-label text-blue">GP Address</label>
                        <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                          value={referral.GPAddress}
                          onChange={handleChangeReferral('GPAddress')}
                        />
                      </div>

                      <div className="form-input">
                        <label className="control-label text-blue">GP Contact No</label>
                        <input type="text" id="firstName" className="form-control" placeholder=""
                          value={referral.GPPhoneNumber}
                          onChange={handleChangeReferral('GPPhoneNumber')}
                        />
                      </div>
                      <div className="form-input">
                        <label className="control-label text-blue">GP PAS Code</label>
                        <input type="text" id="firstName" className="form-control" placeholder=""
                          value={referral.GPPAScode}
                          onChange={handleChangeReferral('GPPAScode')}
                        />
                      </div>
                      <br />
                    </div>
                    <br />
                  </div>
                  <br />
                </div>

              </div>
            </div>
            {/* //END OF COLUMN 3 */}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
          {patient.PatientDischarged ? <Button className="btn marRight20" onClick={() => dispatch({ type: OPEN_MODAL, payload: 'reinstate' })}
          >Reinstate</Button> : <Button className="btn marRight20" onClick={() => dispatch({ type: OPEN_MODAL, payload: 'discharge' })}
            disabled={attendance === 'scheduled' || !referral.AppointmentCancellationReason || viewOption === 'review'}
          >Discharge</Button>}

          <Button type="submit" className="btn btnIri marRight20"
            disabled={referral.AppointmentCancellationReason ? true : false}
          >Next</Button>
          <Button className="btn btnIri marRight20"
            type="reset"
          >Exit</Button>
        </div>

      </Form>
    </>
  );
};


const EnterPatient = () => {



  return (
    <>
      <div className="card-header bg-iri txtLeft">
        <h4 className="m-b-0 text-white">Patient Personal Details</h4>
      </div>
      <div className="card-body">
        {/* <h1>Welcome to enterPatient</h1> */}
        <One />
      </div>
    </>
  )
}

export default EnterPatient;
