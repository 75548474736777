import moment from "moment";
import { CLEAR_DATA, CLEAR_DATASHEET_PICTURES, CLEAR_EXAMINATION, CLEAR_EXAMINATIONS, CLEAR_EXAMINATION_DETAILS, CLEAR_MANAGEMENT_DETAILS, CLEAR_PATIENT_ASSOCIATED_SYMPTOMS, CLEAR_PATIENT_DETAILS, CLEAR_PERSONAL_REFERRAL_DETAILS, CLEAR_PERSONAL_REFERRAL_HISTOLOGY, CLEAR_PERSONAL_RELEVANT_DETAILS, CLEAR_REFERRAL_REASONS, CLEAR_SEARCH_STRING, CLEAR_SMEAR_RESULTS, CLEAR_TREATMENT_DETAILS, SET_DATA, SET_DATASHEET_PICTURES, SET_EXAMINATION, SET_EXAMINATIONS, SET_EXAMINATION_DETAILS, SET_LAST_EXAMINATION, SET_MANAGEMENT_DETAILS, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PATIENT_DETAILS, SET_PEROSNAL_REFERRAL_DETAILS, SET_PEROSNAL_RELEVANT_DETAILS, SET_PERSONAL_REFERRAL_HISTOLOGY, SET_REFERRAL_REASONS, SET_SEARCH_STRING, SET_SMEAR_RESULTS, SET_TREATMENT_DETAILS } from "../actions/types";


const INIT_EXAMINATION_DETAILS = {
  ExaminationDate: moment().format('YYYY-MM-DD'),
  DetailsLetterSummary: '...Colposcopy examination revealed no abnormality.',
  Contraception: 'As before',
  AcetoWhite: false,
  CervixPresent: false,
  OpinionCervical: '',
  OpinionVaginal: 'No Opinion',
  tbl_UserDetails: {}
}

const INIT_MANAGEMENT_DETAILS = {
  ManagementPlan: 'Await Decision'
}

const INIT_TREATMENT_DETAILS = {
  Treatment: 'None',
  TreatmentVaginal: "None",
  TreatmentVaginalVaporisation: 'None'
}


const INIT_STATE = {
  data: [],
  searchString: '',
  patientDetails: {
    MaritalStatus: 'Not known'
  },
  personalReferralDetails: {
    ReferringCentre: "G.P.",
    reasonid: null
  },
  personalReferralHistology: {},
  patientAssociatedSymptoms: {
    VaginalDischarge: "Absent",
    MenstrualState: "Cycling",
    MenstrualLoss: "Average",
    MenstrualCycle: "Regular"

  },
  relevantDetails: {
    Contraception: 'None'
  },
  examinationDetails: INIT_EXAMINATION_DETAILS,
  referralReasons: {
    ReferralSmearCervix: 'No Smear',
    ReferralIndicationCervix: 'Abnormal smear de novo',
    OccurenceOfPreviousSmears: 'Unknown',
    ReferralReasonLetterSummaryCervix: "...referred with no smear"
  },
  treatmentDetails: INIT_TREATMENT_DETAILS,
  smearResults: {},
  examinations: [],
  examination: {},
  lastExamination: {},
  managementDetails: INIT_MANAGEMENT_DETAILS,
  datasheetpictures: []
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DATA: {
      return {
        ...state,
        data: payload
      };
    }
    case CLEAR_DATA: {
      return {
        ...state,
        data: []
      };
    }

    case SET_TREATMENT_DETAILS: {
      return {
        ...state,
        treatmentDetails: payload ? payload : state.treatmentDetails
      };
    }
    case CLEAR_TREATMENT_DETAILS: {
      return {
        ...state,
        treatmentDetails: INIT_TREATMENT_DETAILS
      };
    }

    case SET_SMEAR_RESULTS: {
      return {
        ...state,
        smearResults: payload
      };
    }
    case CLEAR_SMEAR_RESULTS: {
      return {
        ...state,
        smearResults: {}
      };
    }

    case SET_EXAMINATION_DETAILS: {
      return {
        ...state,
        examinationDetails: payload,
        // managementDetails: payload.tbl_CervixManagement ? { ...state.managementDetails, ...payload.tbl_CervixManagement } : state.managementDetails,
        // treatmentDetails: payload.tbl_cervixtreatment ? { ...state.treatmentDetails, ...payload.tbl_cervixtreatment } : state.treatmentDetails
      };
    }

    case CLEAR_EXAMINATION_DETAILS: {
      return {
        ...state,
        examinationDetails: INIT_EXAMINATION_DETAILS,
        examination: {},
        managementDetails: INIT_MANAGEMENT_DETAILS
      };
    }

    case SET_EXAMINATION: {
      return {
        ...state,
        examination: payload,
      };
    }

    case SET_LAST_EXAMINATION: {
      return {
        ...state,
        lastExamination: payload,
      };
    }

    case CLEAR_EXAMINATION: {
      return {
        ...state,
        examination: {},
        lastExamination: {},
        datasheetpictures: []
      };
    }

    case SET_EXAMINATIONS: {
      return {
        ...state,
        examinations: payload
      };
    }

    case CLEAR_EXAMINATIONS: {
      return {
        ...state,
        examinations: []
      };
    }

    case SET_DATASHEET_PICTURES: {
      return {
        ...state,
        datasheetpictures: payload
      };
    }

    case CLEAR_DATASHEET_PICTURES: {
      return {
        ...state,
        datasheetpictures: []
      };
    }

    case SET_PATIENT_DETAILS: {
      return {
        ...state,
        patientDetails: payload,
        // personalReferralDetails: payload.tbl_personalreferraldetails ? payload.tbl_personalreferraldetails[payload.tbl_personalreferraldetails.length - 1] : {}
      };
    }

    case CLEAR_PATIENT_DETAILS: {
      return {
        ...state,
        patientDetails: {},
        personalReferralDetails: {
          ReferringCentre: "G.P.",
          reasonid: null
        },
        patientAssociatedSymptoms: {
          VaginalDischarge: "Absent",
          MenstrualState: "Cycling",
          MenstrualLoss: "Average",
          MenstrualCycle: "Regular"

        },
        relevantDetails: {
          Contraception: 'None'
        },
        personalReferralHistology: {},
        referralReasons: {
          ReferralSmearCervix: 'No Smear',
          ReferralIndicationCervix: 'Abnormal smear de novo',
          OccurenceOfPreviousSmears: 'Unknown',
          ReferralReasonLetterSummaryCervix: "...referred with no smear"
        },
        examinationDetails: INIT_EXAMINATION_DETAILS,
        examinations: [],
        examination: {},
        treatmentDetails: INIT_TREATMENT_DETAILS,
        lastExamination: {},
        datasheetpictures: [],
        managementDetails: INIT_MANAGEMENT_DETAILS

      };
    }




    case SET_PEROSNAL_REFERRAL_DETAILS: {
      return {
        ...state,
        personalReferralDetails: { ...state.personalReferralDetails, ...payload }
      };
    }

    case CLEAR_PERSONAL_REFERRAL_DETAILS: {
      return {
        ...state,
        personalReferralDetails: {
          ReferringCentre: "G.P.",
          ReferrerAddressCervix: "",
          ReferrerNameCervix: "",
          GPAddress: "",
          GPName: "",
          GPPAScode: "",
          GPPhoneNumber: "",
          reasonid: null
        },
        patientAssociatedSymptoms: {
          VaginalDischarge: "Absent",
          MenstrualState: "Cycling",
          MenstrualLoss: "Average",
          MenstrualCycle: "Regular"

        },
        relevantDetails: {
          Contraception: 'None'
        },
        referralReasons: {
          ReferralSmearCervix: 'No Smear',
          ReferralIndicationCervix: 'Abnormal smear de novo',
          OccurenceOfPreviousSmears: 'Unknown',
          ReferralReasonLetterSummaryCervix: "...referred with no smear"
        },
        treatmentDetails: INIT_TREATMENT_DETAILS
      };
    }

    case SET_PERSONAL_REFERRAL_HISTOLOGY: {
      return {
        ...state,
        personalReferralHistology: { ...state.personalReferralHistology, ...payload }
      };
    }

    case CLEAR_PERSONAL_REFERRAL_HISTOLOGY: {
      return {
        ...state,
        personalReferralHistology: {}
      };
    }

    case SET_MANAGEMENT_DETAILS: {
      return {
        ...state,
        managementDetails: { ...state.managementDetails, ...payload }
      };
    }

    case CLEAR_MANAGEMENT_DETAILS: {
      return {
        ...state,
        managementDetails: INIT_MANAGEMENT_DETAILS,
      };
    }

    case SET_PEROSNAL_RELEVANT_DETAILS: {
      return {
        ...state,
        relevantDetails: { ...state.relevantDetails, ...payload }
      };
    }

    case CLEAR_PERSONAL_RELEVANT_DETAILS: {
      return {
        ...state,
        relevantDetails: {},
      };
    }

    case SET_REFERRAL_REASONS: {
      return {
        ...state,
        referralReasons: { ...state.referralReasons, ...payload }
      };
    }

    case CLEAR_REFERRAL_REASONS: {
      return {
        ...state,
        referralReasons: {
          ReferralSmearCervix: 'No Smear',
          ReferralIndicationCervix: 'Abnormal smear de novo',
          OccurenceOfPreviousSmears: 'Unknown',
          ReferralReasonLetterSummaryCervix: "...referred with no smear"
        },
      };
    }

    case SET_PATIENT_ASSOCIATED_SYMPTOMS: {
      return {
        ...state,
        patientAssociatedSymptoms: { ...state.patientAssociatedSymptoms, ...payload }
      };
    }

    case CLEAR_PATIENT_ASSOCIATED_SYMPTOMS: {
      return {
        ...state,
        patientAssociatedSymptoms: {},
      };
    }

    case SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: payload
      };
    }
    case CLEAR_SEARCH_STRING: {
      return {
        ...state,
        searchString: ''
      };
    }
    default:
      return state;
  }
};
