import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, FormFeedback, Input } from 'reactstrap';
import moment from 'moment';
import '../../css/Licensing.css'; // Import your CSS file
import { useDispatch } from 'react-redux';
import BannerLogo from '../../img/Irisoft_logo.png';
import { Grid } from '@mui/material';

export default function SplashScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [entry, setEntry] = useState(null);
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);

    const handleEntry = (e) => {
        e.preventDefault();
        setError(null)
        if((!email || !String(email).includes('@'))){
            setError('Valid Email is required')
            return 
        }
        let str = String(email).split('@')[1].split('.')[0];
        console.log(str);
        if((str === 'site1' || str === 'site2')){
            window.location.href = `https://${str}.compuscope.cloud`
        } else{
            window.location.href = 'https://compuscope.cloud'
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

    }




    return (
        <>
            <div className="licensing-container">
            <div className="licensing-wrapper">
                <div className="bordered p-2">
                    <div className="licensing-viewer p-2">
                        <div className="paper">
                               <img src={BannerLogo} width="auto" height={150}  />
                        </div>
                        <div className='mt-2' style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                            <h6 className='pl-2' style={{ color: "#8f3644", lineHeight: '20px', fontWeight: '600' }}>{moment().format('Do MMMM YYYY')}</h6>
                            <h6 className='pr-2' style={{ color: "#8f3644", fontWeight: '600', lineHeight: '20px' }} >Version 10.0</h6>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
                            <h6 className='pl-2' style={{ color: "#8f3644", lineHeight: '10px', fontWeight: '500' }}>Copyright 2020-2024 IRISOFT Ltd. All rights reserved.</h6>
                            <h6 className='pl-2' style={{ color: "#8f3644", lineHeight: '10px', fontWeight: '500' }}>Use of this product invokes T&C's.</h6>
                        </div>
                        <form className='mt-2' onSubmit={handleEntry}>
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'fle-start', alignItems: 'center' }}>
                    <Grid container spacing={1} className="mr-2">
                    <Grid item xs={12} md={5} >
                        <label className="text-9 text-right pt-2" style={{fontSize: '11px', fontWeight: '500' }}>Enter your NHS email to login</label>
                        </Grid>

                        <Grid item xs={12} md={7} >
                            <Input
                                id="Password" name="Password"
                                className="form-control "
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                invalid={error}
                            />
                            <FormFeedback>
                                {error}
                            </FormFeedback>
                            </Grid>
                        </Grid>

                    </div>

                 
                        <br />
                        <Grid container spacing={1} className=" mr-2">
                        <Grid item xs={12} md={4} >
                            <Button type="submit"  className="btn-action" disabled={entry === 'reportwriter'}
                            >Colposcopy</Button>
                            </Grid>
                            <Grid item xs={12} md={4}  >
                            <Button className="btn-action" disabled >Vulvoscopy</Button>
                            </Grid>
                            <Grid item xs={12} md={4}  >
                            <Button className="btn-action" disabled >More Products</Button>
                            </Grid>
                        </Grid>
                </form>
                        
                    </div>
                </div>
            </div>
            </div>
            

        </>
    )
}
