import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { searchPatientDetails } from './redux/actions/data.action'
import { CLEAR_SORT, OPEN_MODAL, SET_EDITED, SET_PATIENT_DETAILS, SET_PEROSNAL_REFERRAL_DETAILS, SET_REFERRAL_REASONS, SET_SEARCH_STRING, SET_VIEW_OPTION } from './redux/actions/types'

const Home = () => {
  const dispatch = useDispatch()
  const { viewOption } = useSelector(({ ui }) => ui)
  const { patientDetails } = useSelector(({ data }) => data)
  const navigate = useNavigate();
  const [values, setValues] = useState({
    NHSnumber: '',
    HospitalNumber: ''
  })



  useEffect(() => {
    if (patientDetails && patientDetails.SystemID) {
      navigate(viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient')
    }

  }, [patientDetails])

  useEffect(() => {
    let formDetails = localStorage.getItem('formDetails');
    if (formDetails) {
      let { patient, referral, reasons } = JSON.parse(formDetails);
      if (patient && (referral || reasons)) {
        patient && dispatch({ type: SET_PATIENT_DETAILS, payload: patient })
        referral && dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: referral })
        reasons && dispatch({ type: SET_REFERRAL_REASONS, payload: reasons })
        dispatch({ type: SET_EDITED, payload: 'personal' })
      }

    }
  }, [])



  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div className="card-header bg-iri center">
        <h4 className="m-b-0 text-white">Welcome Page</h4>
      </div>
      <div className="card-body bg-white align-items-center justify-content-center">
    
      </div>




    </div>
  )
}

export default Home;
